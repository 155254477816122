import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useAuth } from "./AuthContext";

const BottomBar = () => {
  const { isLoggedIn, userType } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const navigateTo = (path) => {
    window.scrollTo(0, 0);
    navigate(path);
  };

  return (
    <div className="bottom-bar">
      <div
        className={`icon ${
          location.pathname === "/" || location.pathname.startsWith("/results")
            ? "selected"
            : ""
        }`}
        onClick={() => navigateTo("/")}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 12.8858 17.2543 14.5974 16.0417 15.8561C16.0073 15.8825 15.9743 15.9114 15.9428 15.9429C15.9113 15.9744 15.8824 16.0074 15.856 16.0418C14.5973 17.2543 12.8857 18 11 18C7.13401 18 4 14.866 4 11ZM16.6176 18.0319C15.078 19.2635 13.125 20 11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 13.125 19.2635 15.0781 18.0319 16.6177L21.707 20.2929C22.0975 20.6834 22.0975 21.3166 21.707 21.7071C21.3165 22.0976 20.6833 22.0976 20.2928 21.7071L16.6176 18.0319Z"
          />
        </svg>
        <span>Пребарај</span>
      </div>
      {userType == "driver" && (
        <div
          className={`icon ${
            location.pathname === "/my-rides" ? "selected" : ""
          }`}
          onClick={() => navigateTo("/my-rides")}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8 1C7.81314 1 7.63825 1.05125 7.48862 1.14047L0.503861 5.13176C0.192286 5.3098 0 5.64114 0 6V22C0 22.3565 0.189758 22.686 0.498073 22.8649C0.806388 23.0438 1.18664 23.0451 1.49614 22.8682L8.03147 19.1338L15.5348 22.8854C15.6646 22.9538 15.8112 22.9944 15.9669 22.9995C15.9779 22.9998 15.989 23 16 23C16.1869 23 16.3618 22.9487 16.5114 22.8595L23.4961 18.8682C23.8077 18.6902 24 18.3589 24 18V2C24 1.64353 23.8102 1.31401 23.5019 1.13509C23.1936 0.956168 22.8134 0.954898 22.5039 1.13176L15.9685 4.86623L8.4652 1.11457C8.32855 1.04262 8.17321 1.00139 8.00837 1.00003L8 1ZM17 20.2768L22 17.4197V3.72318L17 6.58032V20.2768ZM15 6.61803L9 3.61803V17.382L15 20.382V6.61803ZM2 6.58032L7 3.72318V17.4197L2 20.2768V6.58032Z"
            />
          </svg>
          <span>Патувања</span>
        </div>
      )}
      <div
        className={`icon ${
          location.pathname === "/post-ride" ? "selected" : ""
        }`}
        onClick={() => navigateTo("/post-ride")}
      >
        <svg
          width="26"
          height="27"
          viewBox="0 0 26 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1"
            y="1.76282"
            width="24"
            height="24"
            rx="12"
            st
            stroke-width="2"
          />
          <path d="M14 6.76282C14 6.21053 13.5523 5.76282 13 5.76282C12.4477 5.76282 12 6.21053 12 6.76282V12.7628H6C5.44772 12.7628 5 13.2105 5 13.7628C5 14.3151 5.44772 14.7628 6 14.7628H12V20.7628C12 21.3151 12.4477 21.7628 13 21.7628C13.5523 21.7628 14 21.3151 14 20.7628V14.7628H20C20.5523 14.7628 21 14.3151 21 13.7628C21 13.2105 20.5523 12.7628 20 12.7628H14V6.76282Z" />
        </svg>
        <span>Нов превоз</span>
      </div>
      {/* <div
          className={`icon ${location.pathname === "/saved" ? "selected" : ""}`}
          onClick={() => navigateTo("/")}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.5132 5.36249C8.69377 3.54307 5.74391 3.54307 3.9245 5.36249C2.10508 7.1819 2.10508 10.1318 3.9245 11.9512L4.7042 12.7309C4.71695 12.7436 4.72931 12.7567 4.74129 12.77L12 20.0287L19.2584 12.7703C19.2704 12.7569 19.2829 12.7438 19.2958 12.7309L20.0755 11.9512C21.8949 10.1318 21.8949 7.1819 20.0755 5.36249C18.2561 3.54307 15.3062 3.54307 13.4868 5.36249L12.7071 6.14219C12.3166 6.53272 11.6834 6.53272 11.2929 6.14219L10.5132 5.36249ZM20.7484 14.1067C20.7364 14.1201 20.7239 14.1332 20.711 14.1461L12.7081 22.149C12.5205 22.3366 12.2661 22.442 12.0008 22.4419C12.0005 22.4419 12.0011 22.4419 12.0008 22.4419C12.0005 22.4419 11.9995 22.4419 11.9992 22.4419C11.7339 22.442 11.4795 22.3366 11.2919 22.149L3.28896 14.1461C3.27607 14.1332 3.26359 14.1201 3.25155 14.1067L2.51029 13.3654C-0.0901777 10.7649 -0.0901815 6.54874 2.51028 3.94827C5.11075 1.34781 9.32693 1.34781 11.9274 3.94827L12 4.02087L12.0726 3.94827C14.6731 1.34781 18.8892 1.34781 21.4897 3.94827C24.0902 6.54874 24.0902 10.7649 21.4897 13.3654L20.7484 14.1067Z"
            />
          </svg>
          <span>Допаднати</span>
        </div> */}
      {/* <div
        className={`icon ${location.pathname === "/wallet" ? "selected" : ""}`}
        onClick={() => navigateTo("/wallet")}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 6C0 4.34315 1.34315 3 3 3H21C22.6569 3 24 4.34315 24 6V18C24 19.6569 22.6569 21 21 21H3C1.34315 21 0 19.6569 0 18V6ZM2 6C2 5.44772 2.44772 5 3 5H21C21.5523 5 22 5.44772 22 6V9H2V6ZM22 11V18C22 18.5523 21.5523 19 21 19H3C2.44772 19 2 18.5523 2 18V11H22Z"
          />
        </svg>
        <span>Паричник</span>
      </div> */}
      <div
        className={`icon ${
          location.pathname === "/my-profile" ||
          location.pathname === "/login" ||
          location.pathname === "/register" ||
          location.pathname === "/request-password-reset"
            ? "selected"
            : ""
        }`}
        onClick={() => navigateTo("/my-profile")}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2ZM9 7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7C15 8.65685 13.6569 10 12 10C10.3431 10 9 8.65685 9 7Z"
          />
          <path d="M8 14C6.67392 14 5.40215 14.5268 4.46447 15.4645C3.52678 16.4021 3 17.6739 3 19V21C3 21.5523 3.44772 22 4 22C4.55228 22 5 21.5523 5 21V19C5 18.2043 5.31607 17.4413 5.87868 16.8787C6.44129 16.3161 7.20435 16 8 16H16C16.7956 16 17.5587 16.3161 18.1213 16.8787C18.6839 17.4413 19 18.2044 19 19V21C19 21.5523 19.4477 22 20 22C20.5523 22 21 21.5523 21 21V19C21 17.6739 20.4732 16.4021 19.5355 15.4645C18.5979 14.5268 17.3261 14 16 14H8Z" />
        </svg>
        <span>Профил</span>
      </div>
    </div>
  );
};

export default BottomBar;
