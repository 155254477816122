import React from "react";
import { Container } from "react-bootstrap";

import NavBar from "./NavBar";
import BottomBar from "./BottomBar";

const AboutUs = () => {
  return (
    <div className="has-bottom-bar">
      <NavBar type="white" />
      <BottomBar />
      <Container className="blue-text about-us-container">
        <h1 className="heading-s pt-4 pb-4 mb-0">За нас</h1>
        <p>
          Добредојдовте на нашата платформа за споделување на превоз! Ние сме
          тим кој со страст и посветеност работи на тоа да го направиме
          патувањето поевтино, поудобно и попријатно за сите. Нашата идеја е да
          ви овозможиме да ги споделите трошоците за патувањето со други патници
          кои патуваат во истиот правец, создавајќи еден поекономичен и
          поодржлив начин на транспорт.
        </p>
        <div className=" pb-4 pt-4">
          <h3 className="heading-xxs mb-3">Мисија</h3>
          <p>
            Нашата мисија е да ги слушаме и да се грижиме за нашите корисници.
            Вие сте оние кои ни помагате да растеме и да се подобруваме. Секоја
            ваша повратна информација, предлог или критика е непроценлива за
            нас. Ние веруваме дека заедно можеме да изградиме платформа која ќе
            биде од корист за сите, нудејќи сигурност, удобност и флексибилност
            во патувањето.Тим
          </p>
        </div>
        <div className=" pb-4 pt-4">
          <h3 className="heading-xxs mb-3">Тим</h3>
          <p>
            Зад овој проект стоиме ние, тим од трojца основачи и голем тим на
            поддржувачи. Особена благодарност им изразуваме на сите наши
            поддржувачи, кои со својата посветеност и верба во нашата идеја
            придонесоа да станеме тоа што сме денес. Основачи на оваа платформа
            се Адријан Зарески и Петар Ванчев, со помош од нашиот советник
            Џордан Аико Деја. Заедно, вложуваме многу труд и ентузијазам за да
            ја направиме вашата авантура поедноставна и поевтина. <br />
            <br /> Ве покануваме да станете дел од нашата заедница и да се
            уверите во придобивките од споделувањето на превозот. Со вашата
            поддршка и учество, веруваме дека можеме да постигнеме уште поголеми
            успеси и да ви понудиме уште подобри услуги. Ви благодариме за
            вашата доверба и се радуваме на секое ваше ново патување со нас!{" "}
            <br /> <br />
            Тимот на rideshare
          </p>
        </div>
      </Container>
    </div>
  );
};

export default AboutUs;
